<template>
	<div>
		<div style="z-index: -1; position: fixed; top: 0; left: 0; height: 100%; width: 100%; background-size: cover; background-repeat: no-repeat; background-attachment: scroll; background-image: url('/images/pre_reno/pre_reno_0042.jpeg');">
		</div>
		<div class="container" id="app" style="background-color: #efefef">
			<section class="section is-hero is-fullheight">
				<div class="columns">
					<div class="column">
						<b-carousel :indicator-inside="false" :overlay="gallery" :autoplay="true" @click="switchGallery(true)">
							<b-carousel-item v-for="i in galleryImages" :key="i.id">
								<b-image :src="getImgUrl(i.filename)" ratio="4by3" responsive="true"></b-image>
							</b-carousel-item>
							<span v-if="gallery" @click="switchGallery(false)" class="modal-close is-large" />
						</b-carousel>
					</div>
					<div class="column">
						<div class="block">
							<p class="title is-size-2">
								<strong class="has-text-weight-bold has-text-info-dark">CALYPSO</strong>
								<strong class="has-text-weight-bold has-text-info">402</strong>
							</p>
							<p class="subtitle">6201 Atlantic Ave, Ocean City, MD</p>
						</div>
						<div class="block is-hidden">
							Welcome to our completely renovated oceanfront rental property, where luxury and coastal charm converge. With stunning views of the sparkling ocean, this haven of tranquility offers a respite from the ordinary. Inside, you'll find tastefully furnished interiors, modern amenities, and a serene ambiance that promises an unforgettable experience. Whether you're enjoying the panoramic vistas from the private balcony or exploring the nearby attractions, this coastal gem provides the perfect retreat.
						</div>
						<div class="block">
							2023 - Introducing the New and Improved 402! Embarking on an exciting journey, we commenced a full-scale remodel of our cherished home on October 1, 2022. The anticipation for the finished masterpiece is palpable, and we couldn't be more thrilled. It has been an arduous labor of love, and we invite you to catch a glimpse of our progress through the captivating pictures below. Rest assured, your stay with us promises to be nothing short of extraordinary. Welcome, and may you delight in every moment of your visit!
						</div>
						<div class="block">
							<a href="#google-map" class="button is-info">View Map</a>
						</div>
					</div>
				</div>
				<div class="columns is-multiline is-mobile">
					<!--<b-carousel :indicator-inside="false">
						<b-carousel-item v-for="(item, i) in 6" :key="i">
							<b-image class="image" :src="getImgUrl(i)"></b-image>
						</b-carousel-item>
						<template #indicators="props">
							<b-image class="al image" :src="getImgUrl(props.i)" :title="props.i"></b-image>
						</template>
					</b-carousel>-->
					<b-modal v-model="isImageModalActive">
						<p class="image is-4by3">
							<img :src="modalImg">
						</p>
					</b-modal>
					<div class="column is-one-third" v-for="i in siteImages" :key="i.id">
						<a href="#" @click.prevent="showModal(i.filename)">
							<b-image :src="getImgUrl(i.filename)" :alt="i.filename" ratio="4by3" responsive="true" />
						</a>
					</div>
				</div>
				<div class="columns is-tablet">
					<!--<div class="column">
						<div class="subtitle has-text-weight-bold">
							Dryer Ventilation
						</div>
						<figure class="image is-16by9">
							<iframe
								class="has-ratio"
								width="640"
								height="360"
								src="https://www.youtube.com/embed/DrlDZX602fo"
								title="YouTube video player"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowfullscreen
							></iframe>
						</figure>
					</div>-->
					<div class="column">
						<div class="subtitle has-text-weight-bold">Sofa Delivery</div>
						<figure class="image is-16by9">
							<iframe
								class="has-ratio"
								width="640"
								height="360"
								src="https://www.youtube.com/embed/oF5wOE9lGkw"
								title="YouTube video player"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowfullscreen
							></iframe>
						</figure>
					</div>
				</div>
				<div class="columns">
					<div class="column" id="google-map">
						<div class="subtitle has-text-weight-bold">Google Map</div>
						<div class="map-responsive">
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3127.4209688937326!2d-75.06754928453944!3d38.38551448455831!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b8d6769645373b%3A0x1b4bbded1b335d1d!2s6201%20Atlantic%20Ave%20UNIT%20402%2C%20Ocean%20City%2C%20MD%2021842!5e0!3m2!1sen!2sus!4v1627943439166!5m2!1sen!2sus"
								width="600"
								height="450"
								style="border: 0"
								allowfullscreen=""
								loading="lazy"
							></iframe>
						</div>
					</div>
					<div class="column">
						<div class="subtitle has-text-weight-bold">
							Rentals &amp; Support
						</div>
						<div class="block">
							For any inquiries or concerns throughout your stay, we kindly request you to get in touch with <a href="https://www.shorepro.com/contact">Shoreline Properties</a>. Below, you'll find their contact information, including office, toll-free, and even fax numbers (in case you happen to have a machine from 1994 accompanying you on vacation).
						</div>
						<div class="block">
							<div><strong class="has-text-weight-bold">Office:</strong> (410) 524-6688</div>
							<div><strong class="has-text-weight-bold">Toll Free:</strong> (800) 492-5832</div>
							<div><strong class="has-text-weight-bold">Fax:</strong> (410) 524-4225</div>
						</div>
						<div class="block">
							Feel free to reach out to <a href="https://www.shorepro.com/contact">Shoreline Properties</a> should you require any assistance or have any questions during your time with us.
						</div>
					</div>
				</div>

				<!-- MODAL -->
				<div class="modal" id="modal-img">
					<div class="modal-background"></div>
					<div class="modal-content">
						<p class="image is-4by3">
							<img src="https://bulma.io/images/placeholders/1280x960.png" alt="" />
						</p>
					</div>
					<button class="modal-close is-large" aria-label="close"></button>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
// import pre_reno from "/src/data/pre_reno.js";
import reno from "/src/data/reno.js";

export default {
	name: "App",
	components: {},
	data() {
		return {
			modalImg: '',
			isImageModalActive: false,
			gallery: false,
			images: reno,
		};
	},
  computed: {
	siteImages() {
		return this.images.filter(image => (image.visible == true))
	},
    galleryImages() {
      return this.images.filter(image => (image.gallery == true))
    }
  },
	methods: {
		getImgUrl(value) {
			return `/images/${value}`;
		},
		switchGallery(value) {
			this.gallery = value;
			if (value) {
				return document.documentElement.classList.add("is-clipped");
			} else {
				return document.documentElement.classList.remove("is-clipped");
			}
		},
		showModal(image) {
			this.modalImg = this.getImgUrl(image)
			this.isImageModalActive = true
		}
	},
};
</script>

<style lang="scss">
.carousel.is-overlay .carousel-item img {
	height: 100vh !important ;
}
</style>

<style lang="scss" scoped>
.map-responsive {
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	height: 0;
}
.map-responsive iframe {
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	position: absolute;
}
</style>
