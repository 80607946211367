export default [
    { id: 0, gallery: true, visible: true, filename: "reno/calypso_reno_01.jpg" },
    { id: 1, gallery: true, visible: true, filename: "reno/calypso_reno_02.jpg" },
    { id: 2, gallery: false, visible: true, filename: "reno/calypso_reno_03.jpg" },
    { id: 3, gallery: false, visible: true, filename: "reno/calypso_reno_04.jpg" },
    { id: 4, gallery: false, visible: true, filename: "reno/calypso_reno_05.jpg" },
    { id: 5, gallery: false, visible: true, filename: "reno/calypso_reno_06.jpg" },
    { id: 6, gallery: false, visible: true, filename: "reno/calypso_reno_07.jpg" },
    { id: 7, gallery: false, visible: true, filename: "reno/calypso_reno_08.jpg" },
    { id: 8, gallery: false, visible: true, filename: "reno/calypso_reno_09.jpg" },
    { id: 9, gallery: false, visible: true, filename: "reno/calypso_reno_10.jpg" },
    { id: 10, gallery: false, visible: true, filename: "reno/calypso_reno_11.jpg" },
    { id: 11, gallery: false, visible: true, filename: "reno/calypso_reno_12.jpg" },
    { id: 12, gallery: false, visible: true, filename: "reno/calypso_reno_13.jpg" },
    { id: 13, gallery: false, visible: true, filename: "reno/calypso_reno_14.jpg" },
    { id: 14, gallery: false, visible: true, filename: "reno/calypso_reno_15.jpg" },
    { id: 15, gallery: false, visible: true, filename: "reno/calypso_reno_16.jpg" },
    { id: 16, gallery: false, visible: true, filename: "reno/calypso_reno_17.jpg" },
    { id: 17, gallery: false, visible: true, filename: "reno/calypso_reno_18.jpg" },
    { id: 18, gallery: false, visible: true, filename: "reno/calypso_reno_19.jpg" },
    { id: 19, gallery: false, visible: false, filename: "reno/calypso_reno_20.jpg" },
    { id: 20, gallery: false, visible: false, filename: "reno/calypso_reno_21.jpg" },
    { id: 21, gallery: false, visible: false, filename: "reno/calypso_reno_22.jpg" },
    { id: 22, gallery: false, visible: false, filename: "reno/calypso_reno_23.jpg" },
    { id: 23, gallery: true, visible: true, filename: "pre_reno/pre_reno_0042.jpeg" },   
    { id: 24, gallery: true, visible: true, filename: "pre_reno/pre_reno_0109.jpeg" },
    { id: 25, gallery: false, visible: true, filename: "pre_reno/pre_reno_0112.jpeg" },
    { id: 26, gallery: false, visible: true, filename: "pre_reno/pre_reno_0113.jpeg" },
    { id: 27, gallery: true, visible: true, filename: "pre_reno/pre_reno_0114.jpeg" },
    { id: 28, gallery: true, visible: true, filename: "pre_reno/pre_reno_0115.jpeg" },
    { id: 29, gallery: true, visible: true, filename: "pre_reno/pre_reno_0116.jpeg" },
    { id: 30, gallery: false, visible: true, filename: "pre_reno/pre_reno_0117.jpeg" },
    { id: 31, gallery: false, visible: true, filename: "pre_reno/pre_reno_0125.jpeg" },
    { id: 32, gallery: false, visible: true, filename: "pre_reno/pre_reno_0126.jpeg" },
    { id: 33, gallery: false, visible: true, filename: "pre_reno/pre_reno_0127.jpeg" },
    { id: 34, gallery: false, visible: true, filename: "pre_reno/pre_reno_0128.jpeg" },
    { id: 35, gallery: false, visible: true, filename: "pre_reno/pre_reno_0129.jpeg" },
    { id: 36, gallery: false, visible: false, filename: "pre_reno/pre_reno_0130.jpeg" },
    { id: 37, gallery: false, visible: false, filename: "pre_reno/pre_reno_0131.jpeg" },
    { id: 38, gallery: false, visible: true, filename: "pre_reno/pre_reno_0132.jpeg" },
    { id: 39, gallery: false, visible: true, filename: "pre_reno/pre_reno_0584.png" },
    { id: 40, gallery: false, visible: true, filename: "pre_reno/pre_reno_0586.jpeg" },
    { id: 41, gallery: false, visible: true, filename: "pre_reno/pre_reno_0587.png" },
    { id: 42, gallery: false, visible: true, filename: "pre_reno/pre_reno_0589.png" },
    { id: 43, gallery: false, visible: true, filename: "pre_reno/pre_reno_1011.jpeg" },
    { id: 44, gallery: false, visible: true, filename: "pre_reno/pre_reno_2271.jpeg" },
    { id: 45, gallery: false, visible: true, filename: "pre_reno/pre_reno_2280.jpeg" },
    { id: 46, gallery: false, visible: true, filename: "pre_reno/pre_reno_2281.jpeg" },
    { id: 47, gallery: false, visible: true, filename: "pre_reno/pre_reno_2282.jpeg" },
    { id: 48, gallery: false, visible: true, filename: "pre_reno/pre_reno_2298.jpeg" },
    { id: 49, gallery: false, visible: true, filename: "pre_reno/pre_reno_2299.jpeg" },
    { id: 50, gallery: false, visible: true, filename: "pre_reno/pre_reno_2300.jpeg" },
    { id: 51, gallery: false, visible: true, filename: "pre_reno/pre_reno_2301.jpeg" },
    { id: 52, gallery: false, visible: true, filename: "pre_reno/pre_reno_2302.jpeg" },
    { id: 53, gallery: false, visible: true, filename: "pre_reno/pre_reno_2305.jpeg" },
    { id: 54, gallery: false, visible: true, filename: "pre_reno/pre_reno_2306.jpeg" },
    { id: 55, gallery: false, visible: true, filename: "pre_reno/pre_reno_2307.jpeg" },
    { id: 56, gallery: false, visible: true, filename: "pre_reno/pre_reno_2309.jpeg" },
    { id: 57, gallery: false, visible: true, filename: "pre_reno/pre_reno_2895.jpeg" },
    { id: 58, gallery: false, visible: true, filename: "pre_reno/pre_reno_2896.jpeg" },
    { id: 59, gallery: false, visible: true, filename: "pre_reno/pre_reno_2897.jpeg" },
    { id: 60, gallery: false, visible: true, filename: "pre_reno/pre_reno_2898.jpeg" },
    { id: 61, gallery: false, visible: true, filename: "pre_reno/pre_reno_2899.jpeg" },
    { id: 62, gallery: false, visible: true, filename: "pre_reno/pre_reno_2900.jpeg" },
    { id: 63, gallery: false, visible: true, filename: "pre_reno/pre_reno_2901.jpeg" },
    { id: 64, gallery: false, visible: true, filename: "pre_reno/pre_reno_2903.jpeg" },
    { id: 65, gallery: false, visible: true, filename: "pre_reno/pre_reno_2904.jpeg" },
    { id: 66, gallery: false, visible: true, filename: "pre_reno/pre_reno_2905.jpeg" },
]
